<template>
  <b-overlay :show="isLoading">
    <b-modal id="unlock-player-promotion-modal" title="ปลดโปรโมชั่น" hide-footer>
      <b-form-checkbox v-model="removePromotion">
        ลบประวัติการรับโปรโมชั่น
      </b-form-checkbox>

      <div class="text-right">
        <b-button variant="light" class="mr-2" @click="onCloseModal">
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-overlay
          :show="isLoading"
          rounded
          spinner-small
          class="d-inline-block"
        >
          <b-button type="submit" variant="primary" block @click="onSubmit">
            {{ $t('buttons.confirm') }}
          </b-button>
        </b-overlay>
      </div>
    </b-modal>
  </b-overlay>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'UnlockPlayerPromotionModal',
  data() {
    return {
      removePromotion: false,
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.player.isUnlockingPlayerPromotion,
    })
  },
  methods: {
    onCloseModal() {
      this.$bvModal.hide('unlock-player-promotion-modal')
    },
    onSubmit() {
      this.$emit('submit', { removePromotion: this.removePromotion })
    },
  },
}
</script>
